const cookie = {
    data() {
        return {
            cookieChecker_show: false,
        }
    },
    methods: {
        cookie_set(cname, cvalue, exdays) {
            const d = new Date()
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
            const expires = `expires=${d.toGMTString()}`
            document.cookie = `${cname}=${cvalue};${expires};path=/`
        },
        cookie_get(cname) {
            const cookieCheck = `${cname}=`
            const decodedCookie = decodeURIComponent(document.cookie)
            const ca = decodedCookie.split(';')
            for (let i = 0; i < ca.length; i += 1) {
                let c = ca[i]
                while (c.charAt(0) === ' ') {
                    c = c.substring(1)
                }
                if (c.indexOf(cookieCheck) === 0) {
                    return c.substring(cookieCheck.length, c.length)
                }
            }
            return ''
        },
        cookie_check() {
            const isCheck = this.cookie_get('cookieCheck')

            if (isCheck === '1') {
                this.cookieChecker_show = false
            } else {
                this.cookieChecker_show = true
            }
        },
        cookie_toggle() {
            this.cookie_set('cookieCheck', 1, 30)
            this.cookieChecker_show = false
        },
    },
    mounted() {
        this.cookie_check()
    },
}

export default cookie
