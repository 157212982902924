import Vue from 'vue'
import Vuex from 'vuex'
import cookie from './modules/cookie'
import defaults from './modules/defaults'
import sideboxCSR from './modules/sideboxCSR'
import sideboxEDM from './modules/sideboxEDM'
import wheel from './modules/wheel'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    modules: {
        defaults,
        cookie,
        sideboxCSR,
        sideboxEDM,
        wheel,
    },
})
