// import PerfectScrollbar from 'perfect-scrollbar'

// "js-menu-togtriggergle"
// "js-menu-content"
class Menu {
    constructor() {
        this.trigger = document.getElementsByClassName('js-menu-trigger')[0]
        this.content = document.getElementsByClassName('js-menu-content')[0]
        this.ps = null
        this.isopen = false
        this.body = document.getElementsByTagName('body')[0]
    }

    toggle() {
        if (this.isopen) {
            this.content.classList.add('_leaving')
            this.body.classList.remove('_ban')
            this.trigger.classList.remove('_active')
            this.isopen = !this.isopen
            setTimeout(() => {
                this.content.classList.remove('_active')
            }, 320)
            setTimeout(() => {
                this.content.classList.remove('_leaving')
            }, 900)
        } else {
            this.trigger.classList.add('_active')
            this.content.classList.add('_active')
            this.body.classList.add('_ban')
            this.isopen = !this.isopen
        }
    }

    togglewithPS() {
        // 開
        if (this.isopen) {
            this.content.classList.add('_leaving')
            this.body.classList.remove('_ban')
            this.trigger.classList.remove('_active')
            this.ps.destroy()
            this.ps = null
            this.isopen = !this.isopen
            setTimeout(() => {
                this.content.classList.remove('_active')
            }, 320)
            setTimeout(() => {
                this.content.classList.remove('_leaving')
            }, 900)
        } else {
            this.trigger.classList.add('_active')
            this.content.classList.add('_active')
            this.body.classList.add('_ban')
            this.ps = new PerfectScrollbar(this.content, {
                wheelSpeed: 2,
                minScrollbarLength: 20,
            })
            this.isopen = !this.isopen
        }
    }

    open() {
        this.trigger.classList.add('_active')
        this.content.classList.add('_active')
    }

    close() {
        this.trigger.classList.remove('_active')
        this.content.classList.remove('_active')
    }
}


export default Menu
