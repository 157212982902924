const state = () => ({
    currentStep: 0,
    isTransition: false,
    transitionState: 'stop', // start = 開場, leaving = 離開, stop = 靜止, tonext = 往下, toprev = 往前
})

const actions = {
    moveIndexSection({ commit }, payload) {
        commit('moveTo', {
            step: payload.step,
            direction: payload.direction,
        })
    },
    moveIndexSectionState({ commit }, payload) {
        commit('moveState', {
            direction: payload.direction,
            isMove: payload.isMove,
        })
    },
    move({ commit }, payload) {
        commit('isMove', {
            isMove: payload.isMove,
        })
    },
}

const mutations = {
    // moveState(states, n) {
    //     states.transitionState = n.direction
    // },
    isMove(states, n) {
        states.isTransition = n.isMove
    },
    moveTo(states, n) {
        states.transitionState = n.direction
        states.currentStep = n.step
    },
    moveState(states, n) {
        states.transitionState = n.direction
        states.isTransition = n.isMove
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
