import menuMixin from '../mixin/public/menu_mixin'
import resizeMixin from '../mixin/helpers/resize_mixin'
let dataJson;

dataJson = JSON.parse(document.getElementById('dataJson').textContent)
new Vue({
    el: '#header',
    name: 'Header',
    delimiters: ['${', '}'],
    mixins: [menuMixin, resizeMixin],
    data() {
        return {

            hoverTitleId: 0,
            hoverDropmenuId: 0,
            hoverSearchId: 0,
            searchState: false,
            banner_up:dataJson.banner_up, 
            banner_down:dataJson.banner_down
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.addEventListener('scroll', this.handleScroll)
    },
    computed: {},
    methods: {
        handleScroll() {
            this.searchState = false;
            document.getElementById('p_menu').classList.add('hidden');
            if (window.pageYOffset <= 50) {
                document.getElementById('header').classList.remove('_active');
            } else {
                document.getElementById('header').classList.add('_active');
            }
            //document.getElementById('org_list').classList.add('hidden');
        }
    },
    mounted() {
        document.getElementById('header').classList.remove('_active');
        window.addEventListener('scroll', this.handleScroll);
        document.body.addEventListener("click", function (e) {
            // if (document.getElementById("org_btn").contains(e.target)) {
            //     document.getElementById('org_list').classList.toggle('hidden');
            // } else {
            //     document.getElementById('org_list').classList.add('hidden');
            // }
            if (!document.getElementById("i_menu").contains(e.target)) {
                document.getElementById('p_menu').classList.add('hidden');
            } else {
                document.getElementById('p_menu').classList.toggle('hidden');
            }
        });
    },
});
