// 放一些全站共用的參數
const state = () => ({
    currentLang: document.documentElement.lang,
    currentedPage: document.getElementsByTagName('main')[0].id,
    cursorColor: '#7CF0FF',
    blendColor: '#006FFF',
    normalColor: '#0D4FB7',
    darkColor: '#153364',
    logoColored: false,
    panelColor: 'white',
    isLoading: true,
})

const actions = {
    handlePageDefaultColorArr({ commit }, payload) {
        commit('initPageDefaultColorTo', {
            color: payload.colorArr,
        })
    },
    handleLogoColor({ commit }, payload) {
        commit('updateLogoColor', {
            bool: payload.bools,
        })
    },
    handlePanelColorTo({ commit }, payload) {
        commit('updatePanelColorTo', {
            color: payload.colors,
        })
    },
    handleLoading({ commit }) {
        commit('updateLoading')
    },
}

const mutations = {
    initPageDefaultColorTo(states, n) {
        states.cursorColor = n.color.cursor_color
        states.blendColor = n.color.blend_color
        states.normalColor = n.color.normal_color
        states.darkColor = n.color.dark_color
    },
    updateLogoColor(states, n) {
        states.logoColored = n.bool
    },
    updatePanelColorTo(states, n) {
        states.panelColor = n.color
    },
    updateLoading(states) {
        states.isLoading = false
    },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
