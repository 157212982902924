import 'lazysizes'
import Vuex from 'vuex'
import store from './store'

window.Vue = require('vue').default;

Vue.use(Vuex);
window.store = store;

// require('./modules/alertbox');
// require('./modules/loading');
require('./modules/footer');
require('./modules/header');
// require('./modules/totop');
// require('./modules/cookie');
// require('./modules/notification');
