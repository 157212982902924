const state = () => ({
    isSideboxCSR: false,
    sideboxType: null,
    data: null,
    title: null,
})

const actions = {
    closeSideboxCSR({ commit }) {
        commit('close')
    },
    callSideboxCSRby({ commit }, payload) {
        commit('openby', {
            types: payload.type,
            titles: payload.title,
            datas: payload.data,
        })
    },
}

const mutations = {
    close(states) {
        states.isSideboxCSR = false
        states.sideboxType = null
    },
    openby(states, n) {
        states.sideboxType = n.types
        states.data = n.datas
        states.title = n.titles
        states.isSideboxCSR = true
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
