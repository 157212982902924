import Vue from 'vue'

new Vue({
    el: '#footer',
    name: 'Footer',
    delimiters: ['${', '}'],
    data() {
        return {

        }
    },
    methods: {
    },
});
