import Menu from '../../components/Menu'

const menuMixin = {
    data() {
        return {
            menu: null,
        }
    },
    methods: {
        clickMenu() {
            this.menu.toggle()
        },
    },
    mounted() {
        this.menu = new Menu()
    },
}
export default menuMixin
