const state = () => ({
    isSideboxEDM: false,
})

const actions = {
    callSideboxEDM({ commit }) {
        commit('toggle')
    },
}

const mutations = {
    toggle(states) {
        states.isSideboxEDM = !states.isSideboxEDM
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
