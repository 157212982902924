const resizeMixin = {
    data() {
        return {
            menu: null,
        }
    },
    created() {
        window.addEventListener('resize', this.onResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize() {
            if (this.$refs.menuTrigger.classList.contains('_active') && this.$refs.menuContent.classList.contains('_active')) {
                this.$refs.menuTrigger.classList.remove('_active')
                this.$refs.menuContent.classList.remove('_active')
            }
        },
    },
}
export default resizeMixin
